import React, { createContext, useContext, useState } from "react";

const ThemeContext = createContext();
const LanguageContext = createContext();

export const useTheme = () => useContext(ThemeContext);
export const useLanguage = () => useContext(LanguageContext);

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("Pro"); // Default theme

  const changeToPro = () => {
    setTheme("Pro");
  };

  const changeToUNSW = () => {
    setTheme("UNSW");
  };

  const changeToUMelb = () => {
    setTheme("UMelb");
  };

  const changeToMQU = () => {
    setTheme("MQU");
  };

  return (
    <ThemeContext.Provider
      value={{ theme, changeToUNSW, changeToPro, changeToUMelb, changeToMQU }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("English"); // Default language

  const changeToEnglish = () => {
    setLanguage("English");
  };

  const changeToChinese = () => {
    setLanguage("Chinese");
  };

  return (
    <LanguageContext.Provider
      value={{ language, changeToEnglish, changeToChinese }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
