import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./styles/EntryPage.module.css";
import { useLanguage } from "./ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import wordsJson from "./words.json";
import { useNavigate } from "react-router-dom"; // Import useNavigate

function EntryPage() {
  const [hoveredButton, setHoveredButton] = useState(null);

  const navigate = useNavigate(); // Get navigate function
  const selectTheme = (theme) => {
    navigate(`/${theme}`); // Navigate to the theme-based route
  };

  const { changeToEnglish, changeToChinese } = useLanguage();
  const { language } = useLanguage();

  const adminContent = wordsJson.admin[language].entryPageSection;

  const handleLanguageChange = () => {
    if (language === "English") {
      changeToChinese();
    } else {
      changeToEnglish();
    }
  };

  return (
    <motion.div
      className={`${styles.firstPage} ${
        hoveredButton === "pro"
          ? styles.firstPageProHovered
          : hoveredButton === "unsw"
          ? styles.firstPageUNSWHovered
          : hoveredButton === "umelb"
          ? styles.firstPageUMelbHovered
          : ""
      }`}
      initial={{ opacity: 0.1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, display: "none" }}
      transition={{ duration: 0.8 }}
    >
      <div
        className={styles.languageChangeButton}
        onClick={handleLanguageChange}
      >
        <FontAwesomeIcon icon={fas.faEarthAmericas} />
        &nbsp;
        {adminContent.languageButton}
      </div>
      <h1 className={styles.header}>{adminContent.titleTop} </h1>
      <h1 className={styles.header}>{adminContent.titleBottom} </h1>
      <button
        className={`${styles.themeSelectButton} ${styles.ProButton}`}
        onMouseEnter={() => setHoveredButton("pro")}
        onMouseLeave={() => setHoveredButton(null)}
        onClick={() => {
          selectTheme("pro");
        }}
      >
        {adminContent.proButton}
      </button>
      <button
        className={`${styles.themeSelectButton} ${styles.UNSWButton}`}
        onMouseEnter={() => setHoveredButton("unsw")}
        onMouseLeave={() => setHoveredButton(null)}
        onClick={() => {
          selectTheme("unsw");
        }}
      >
        {adminContent.unswButton}
      </button>
      <button
        className={`${styles.themeSelectButton} ${styles.UMelbButton}`}
        onMouseEnter={() => setHoveredButton("umelb")}
        onMouseLeave={() => setHoveredButton(null)}
        onClick={() => {
          selectTheme("umelb");
        }}
      >
        {adminContent.melbButton}
      </button>
    </motion.div>
  );
}

export default EntryPage;
