import React from "react";
import { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import styles from "./styles/DonutChart.module.css";

ChartJS.register(ArcElement, Tooltip);

const DonutChart = () => {
  const [chartData, setChartData] = useState("university");

  const universityData = {
    title: "University",
    labels: [
      "UNSW",
      "USYD",
      "UniMelb",
      "ANU",
      "Macquarie",
      "Monash",
      "UTS",
      "UQLD",
      "Other",
    ],
    datasets: [
      {
        data: [29, 22, 18, 7, 6, 5, 3, 2, 8],
        backgroundColor: [
          "#FFCC00",
          "#FF0000",
          "#0033A0",
          "#000000",
          "#800000",
          "#003366",
          "#007A33",
          "#4B0082",
          "#808080",
        ],
      },
    ],
  };

  const genderData = {
    title: "Gender",
    labels: ["Female", "Male"],
    datasets: [
      {
        data: [52, 48],
        backgroundColor: ["#FF6384", "#36A2EB"],
      },
    ],
  };

  const locationData = {
    title: "Location",
    labels: ["Sydney", "Melbourne", "Overseas", "Canberra"],
    datasets: [
      {
        data: [72, 16, 11, 1],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
      },
    ],
  };

  const qualificationData = {
    title: "Qualification",
    labels: [
      "CFA",
      "CPA",
      "CA",
      "Law",
      "FRM",
      "FIAA",
      "Other",
      "None",
      "AIAA/CIMA/ACCA",
    ],
    datasets: [
      {
        data: [39, 16, 14, 4, 4, 4, 13, 4, 1],
        backgroundColor: [
          "#FFCC00",
          "#FF0000",
          "#0033A0",
          "#000000",
          "#800000",
          "#003366",
          "#007A33",
          "#4B0082",
          "#808080",
        ],
      },
    ],
  };

  const employmentData = {
    title: "Employment",
    labels: [
      "IBD",
      "Big4",
      "Bank",
      "Insurance",
      "Super",
      "Gov",
      "Funds",
      "Commercial",
      "Consulting",
      "Law",
    ],
    datasets: [
      {
        data: [11, 17, 17, 10, 4, 6, 16, 12, 4, 3],
        backgroundColor: [
          "#FFCC00",
          "#FF0000",
          "#0033A0",
          "#000000",
          "#800000",
          "#003366",
          "#007A33",
          "#4B0082",
          "#808080",
          "#6B0000",
        ],
      },
    ],
  };

  const professionalExperienceData = {
    title: "Experience",
    labels: ["< 2 years", "2-5 years", "5-10 years", "10+ years"],
    datasets: [
      {
        data: [17, 31, 35, 18],
        backgroundColor: [
          "#FFCC00",
          "#FF0000",
          "#0033A0",
          "#000000",
          "#800000",
          "#003366",
          "#007A33",
          "#4B0082",
          "#808080",
          "#FFCE56",
          "#36A2EB",
        ],
      },
    ],
  };

  const dataMap = {
    university: universityData,
    gender: genderData,
    location: locationData,
    qualification: qualificationData,
    employment: employmentData,
    professionalExperience: professionalExperienceData,
  };

  const data = dataMap[chartData];

  const options = {
    responsive: true,
    cutout: "45%",
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || "";
            const value = context.raw || 0;
            const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const percentage = ((value / total) * 100).toFixed(0);
            return `${label}: ${percentage}%`;
          },
        },
        position: "nearest",
        yAlign: "bottom",
        xAlign: "center",
      },
    },
  };

  const getTextColor = (backgroundColor) => {
    const color = backgroundColor.substring(1); // remove the '#'
    const rgb = parseInt(color, 16); // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff; // extract red
    const g = (rgb >> 8) & 0xff; // extract green
    const b = (rgb >> 0) & 0xff; // extract blue
    const brightness = 0.299 * r + 0.587 * g + 0.114 * b; // calculate brightness
    return brightness > 128 ? "#000" : "#fff"; // return black for bright colors, white for dark colors
  };

  const plugins = [
    {
      id: "innerText",
      afterDatasetsDraw: (chart) => {
        const ctx = chart.ctx;
        chart.data.datasets.forEach((dataset, i) => {
          const meta = chart.getDatasetMeta(i);
          meta.data.forEach((element, index) => {
            const model = element;
            const total = dataset.data.reduce((a, b) => a + b, 0);
            const percentageValue = (
              (dataset.data[index] / total) *
              100
            ).toFixed(1);
            if (percentageValue >= 4) {
              const labelText = chart.data.labels[index];
              const dataValue = dataset.data[index];

              const startAngle = model.startAngle;
              const endAngle = model.endAngle;
              const angle = startAngle + (endAngle - startAngle) / 2;

              const centerX = chart.getDatasetMeta(0).data[0].x;
              const centerY = chart.getDatasetMeta(0).data[0].y;
              const radius = model.outerRadius * 0.73;
              const x = centerX + Math.cos(angle) * radius;
              const y = centerY + Math.sin(angle) * radius;

              const backgroundColor = dataset.backgroundColor[index];
              const textColor = getTextColor(backgroundColor);

              ctx.save();
              ctx.translate(x, y);
              if (angle > Math.PI / 2) {
                ctx.rotate(angle - Math.PI);
              } else {
                ctx.rotate(angle);
              }
              ctx.textAlign = "center";
              ctx.textBaseline = "middle";
              ctx.fillStyle = textColor;
              ctx.font = "bold 12px Arial";
              ctx.fillText(`${labelText}`, 0, 0);
              ctx.restore();
            }
          });
        });
      },
    },
  ];

  const handleDropdownChange = (event) => {
    if (event === "Num Professionals") {
      return;
    }
    setChartData(event.target.value);
  };

  return (
    <div className={styles.chartContainer}>
      <div className={styles.dropdownContainer}>
        <select onChange={handleDropdownChange} className={styles.dropdown}>
          <option value="university">University</option>
          <option value="gender">Gender</option>
          <option value="location">Location</option>
          <option value="qualification">Qualification</option>
          <option value="employment">Employment</option>
          <option value="professionalExperience">
            Professional Experience
          </option>
        </select>
      </div>
      <div className={styles.title}>{data.title}</div>
      <Doughnut
        className={styles.donut}
        data={data}
        options={options}
        plugins={plugins}
      />
    </div>
  );
};

export default DonutChart;
