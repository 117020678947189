// BecomeMember.js
import React from "react";
import styles from "./styles/BecomeMember.module.css";
import { useTheme, useLanguage } from "./ThemeContext";
import wordsJson from "./words.json";

function SocialMedia() {
  const { language } = useLanguage();
  const headingContent = wordsJson.admin[language].becomeMemberSection;

  const { theme } = useTheme();
  const wordsData = wordsJson[theme][language].becomeMember;

  // use this generate https://mailtolinkgenerator.com/
  const handleClick = () => {
    window.open(wordsData.memberLink, "_blank", "noopener,noreferrer");
  };

  return (
    <div id="BecomeMember" className={styles.box}>
      <div className={styles.container}>
        <div className={styles.description_container}>
          <h2 className={styles.title}>{headingContent.title}</h2>
          {wordsData.description.map((paragraph, index) => (
            <p key={index} className={styles.description}>
              {paragraph}
            </p>
          ))}
        </div>

        <div className={styles.signup_container}>
          <div className={styles.signup_container_visible}>
            <h1 className={styles.signup_title}>{wordsData.membershipType}</h1>
            <div className={styles.signup_price}>{wordsData.price}</div>
            {wordsData.benefits.map((paragraph, index) => (
              <p key={index} className={styles.signup_benefit}>
                {paragraph}
              </p>
            ))}

            <div className={styles.signup_button} onClick={handleClick}>
              {headingContent.joinUsButton}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialMedia;
