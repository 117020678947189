import React from "react";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCards } from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import aboutUsContent from "./words.json";
import styles from "./styles/AboutUs.module.css";
import { useTheme, useLanguage } from "./ThemeContext";
import ProTheme from "./styles/PRO/AboutUs.module.css";
import UNSWTheme from "./styles/UNSW/AboutUs.module.css";
import MQUTheme from "./styles/MQU/AboutUs.module.css";
import UMelbTheme from "./styles/UMelb/AboutUs.module.css";
import unswRegLogo from "./img/AboutUs/unswRegLogo.png";
import umelbRegLogo from "./img/AboutUs/umelbRegLogo.png";
import DonutChart from "./DonutChart";
import { motion } from "framer-motion";

function AboutUs() {
  const { language } = useLanguage();
  const headingContent = aboutUsContent.admin[language].aboutUsSection;
  const { theme } = useTheme();
  function getThemeStyles(theme) {
    switch (theme) {
      case "Pro":
        return ProTheme;
      case "UNSW":
        return UNSWTheme;
      case "UMelb":
        return UMelbTheme;
      case "MQU":
        return MQUTheme;
      default:
        return ProTheme;
    }
  }
  const themeStyles = getThemeStyles(theme);
  const content = aboutUsContent[theme][language].aboutUsContent;

  const [isProStats, setisProStats] = useState(false);

  const handleProStats = (isProStats) => () => {
    setisProStats(isProStats);
  };

  return (
    <section className={`${styles.container} ${themeStyles.container}`}>
      <div
        id="AboutUs"
        className={`${styles.description_container} ${themeStyles.description_container}`}
      >
        <h2 className={`${styles.heading} ${themeStyles.heading}`}>
          {headingContent.title}
        </h2>
        {content.map((paragraph, index) => (
          <p key={index} className={`${styles.text} ${themeStyles.text}`}>
            {paragraph}
          </p>
        ))}
      </div>

      <div className={styles.stats_container}>
        <Swiper
          className={styles.swiper}
          effect={"cards"}
          grabCursor={true}
          modules={[Autoplay, EffectCards]}
          autoplay={{
            delay: 2000,
            disableOnInteraction: true,
          }}
        >
          {theme === "Pro" && (
            <SwiperSlide
              className={`${styles.swiper_slide} ${themeStyles.swiper_slide}`}
            >
              <div className={styles.small_container}>
                <i className={styles.icon}>
                  <FontAwesomeIcon icon={fas.faRegistered} />
                </i>
                <div>NSW Registered</div>
              </div>
            </SwiperSlide>
          )}
          {theme === "UNSW" && (
            <SwiperSlide
              className={`${styles.swiper_slide} ${themeStyles.swiper_slide}`}
            >
              <div className={styles.small_container}>
                <img
                  className={styles.regLogoImg}
                  src={unswRegLogo}
                  alt="Error"
                />
                <div>Arc Registered</div>
              </div>
            </SwiperSlide>
          )}
          {theme === "UMelb" && (
            <SwiperSlide
              className={`${styles.swiper_slide} ${themeStyles.swiper_slide}`}
            >
              <div className={styles.small_container}>
                <img
                  className={styles.regLogoImg}
                  src={umelbRegLogo}
                  alt="Error"
                />
                <div>UMSU Registered</div>
              </div>
            </SwiperSlide>
          )}

          <SwiperSlide
            className={`${styles.swiper_slide} ${themeStyles.swiper_slide}`}
          >
            <div className={styles.small_container}>
              <i className={styles.icon}>
                <FontAwesomeIcon icon={fab.faWeixin} />
              </i>
              <div>15000+ Followers</div>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className={`${styles.swiper_slide} ${themeStyles.swiper_slide}`}
          >
            {!isProStats && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0.5 }}
                transition={{ duration: 1 }}
                className={styles.small_container}
              >
                <i className={styles.icon}>
                  <FontAwesomeIcon icon={fab.faBlackTie} />
                  <button
                    className={styles.viewProStatsButton}
                    onClick={handleProStats(true)}
                  >
                    More Stats &nbsp;
                    <FontAwesomeIcon icon={fas.faChartSimple} />
                  </button>
                </i>
                300+ Professionals
              </motion.div>
            )}

            {isProStats && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0.5 }}
                transition={{ duration: 1 }}
                className={styles.small_container}
              >
                <FontAwesomeIcon
                  onClick={handleProStats(false)}
                  className={styles.closeIcon}
                  icon={fas.faClose}
                />
                <DonutChart />
              </motion.div>
            )}
          </SwiperSlide>
          <SwiperSlide
            className={`${styles.swiper_slide} ${themeStyles.swiper_slide}`}
          >
            <div className={styles.small_container}>
              <i className={styles.icon}>
                <FontAwesomeIcon icon={fas.faGraduationCap} />
              </i>
              3000+ Students
            </div>
          </SwiperSlide>
          <SwiperSlide
            className={`${styles.swiper_slide} ${themeStyles.swiper_slide}`}
          >
            <div className={styles.small_container}>
              <i className={styles.icon}>
                <FontAwesomeIcon icon={fas.faCalendarDays} />
              </i>
              100+ Events
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
}

export default AboutUs;
