// Navbar.js
import styles from "./styles/Navbar.module.css";
import { useTheme, useLanguage } from "./ThemeContext";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import ProTheme from "./styles/PRO/Navbar.module.css";
import UNSWTheme from "./styles/UNSW/Navbar.module.css";
import MQUTheme from "./styles/MQU/Navbar.module.css";
import UMelbTheme from "./styles/UMelb/Navbar.module.css";

import proLogo from "./img/logo/pro.png";
import unswLogo from "./img/logo/unsw.png";
import umelbLogo from "./img/logo/umelb.png";
import mquLogo from "./img/logo/unsw.png";

import { Spin as Hamburger } from "hamburger-react";

import wordsJson from "./words.json";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";

import { AnimatePresence, motion } from "framer-motion";

function Navbar() {
  // Language Changer
  const { changeToEnglish, changeToChinese } = useLanguage();
  const { language } = useLanguage();
  const [wordsContent, setWordsContent] = useState(
    wordsJson.admin[language].navigationSection
  );

  // Theme Changer
  const { theme } = useTheme();

  const themes = {
    Pro: { styles: ProTheme, logo: proLogo },
    UNSW: { styles: UNSWTheme, logo: unswLogo },
    UMelb: { styles: UMelbTheme, logo: umelbLogo },
    MQU: { styles: MQUTheme, logo: mquLogo },
    default: { styles: ProTheme, logo: proLogo },
  };

  function getThemeSettings(theme) {
    return themes[theme] || themes.default;
  }

  const { styles: themeStyles, logo } = getThemeSettings(theme);
  const [currentTheme, setCurrentTheme] = useState(wordsContent.proSocName);

  useEffect(() => {
    setWordsContent(wordsJson.admin[language].navigationSection);
    switch (theme) {
      case "Pro":
        setCurrentTheme(wordsJson.admin[language].navigationSection.proSocName);
        break;
      case "UNSW":
        setCurrentTheme(
          wordsJson.admin[language].navigationSection.unswSocName
        );
        break;
      case "UMelb":
        setCurrentTheme(
          wordsJson.admin[language].navigationSection.melbSocName
        );
        break;
      case "MQU":
        setCurrentTheme(wordsJson.admin[language].navigationSection.mquSocName);
        break;
      default:
        setCurrentTheme(wordsJson.admin[language].navigationSection.proSocName);
    }
  }, [language, theme]);

  const navigate = useNavigate(); // Get navigate function
  const handleThemeChange = (changeTheme, theme) => {
    navigate(`/${changeTheme}`); // Navigate to the theme-based route
  };

  const handleLanguageChange = () => {
    if (language === "English") {
      changeToChinese();
    } else {
      changeToEnglish();
    }
  };

  // Dropdown menu open until click outside
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const dropdownRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Scroll to a section by ID
  const isProgrammaticScroll = useRef(false);
  const scrollToSection = (sectionId) => {
    setActiveSection(sectionId);
    const section = document.getElementById(sectionId);
    if (section) {
      isProgrammaticScroll.current = true;
      if (window.innerWidth > 768) {
        section.scrollIntoView({ behavior: "smooth", block: "center" });
      } else {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
      }
      setTimeout(() => {
        isProgrammaticScroll.current = false;
      }, 1000); // Adjust the timeout duration as needed
    } else {
      console.error("Section ID not found:", sectionId);
    }
  };

  const [activeSection, setActiveSection] = useState("AboutUs");

  useEffect(() => {
    const handleScroll = () => {
      if (isProgrammaticScroll.current) return;
      const sections = [
        "AboutUs",
        "ComingEvents",
        "BlogSection",
        "BecomeMember",
      ];
      const scrollPosition = window.scrollY;
      const viewportHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (
        scrollPosition + viewportHeight >=
        documentHeight - viewportHeight / 2
      ) {
        setActiveSection("BecomeMember");
      } else {
        for (const section of sections) {
          const element = document.getElementById(section);
          if (element) {
            const { offsetTop, offsetHeight } = element;
            if (
              scrollPosition >= offsetTop &&
              scrollPosition < offsetTop + offsetHeight
            ) {
              setActiveSection(section);
              break;
            }
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <section className={`${styles.navbar} ${themeStyles.navbar}`}>
      <div className={`${styles.logo} ${themeStyles.logo}`}>
        <img
          src={logo}
          alt="发消息AWSUNSW微信小助手 id:aws__unsw (Note 有id有两个下划线), 告诉他你要找Tong Zhou, 对这个网站Project感兴趣"
        />

        <div
          className={`${styles.themeDropdown} ${themeStyles.themeDropdown} ${
            isDropdownOpen ? styles.open : ""
          } ${isDropdownOpen ? themeStyles.open : ""}`}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <button
            className={`${styles.themeDropdownButton} ${themeStyles.themeDropdownButton}`}
          >
            {wordsContent.socName}
            {currentTheme}
          </button>
          <AnimatePresence>
            {isDropdownOpen && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.3 }}
                className={`${styles.themeDropdownContainer} ${themeStyles.themeDropdownContainer}`}
              >
                {currentTheme !== wordsContent.proSocName && (
                  <div>
                    <button
                      className={`${styles.themeButton} ${ProTheme.themeButton}`}
                      onClick={() =>
                        handleThemeChange("pro", wordsContent.proSocName)
                      }
                    >
                      {wordsContent.proSocName}
                    </button>
                  </div>
                )}
                {currentTheme !== wordsContent.unswSocName && (
                  <div>
                    <button
                      className={`${styles.themeButton} ${UNSWTheme.themeButton}`}
                      onClick={() =>
                        handleThemeChange("unsw", wordsContent.unswSocName)
                      }
                    >
                      {wordsContent.unswSocName}
                    </button>
                  </div>
                )}
                {currentTheme !== wordsContent.melbSocName && (
                  <div>
                    <button
                      className={`${styles.themeButton} ${UMelbTheme.themeButton}`}
                      onClick={() =>
                        handleThemeChange("umelb", wordsContent.melbSocName)
                      }
                    >
                      {wordsContent.melbSocName}
                    </button>
                  </div>
                )}
                {/* {currentTheme !== wordsContent.mquSocName && (
                <div><button className={`${styles.themeButton} ${MQUTheme.themeButton}`} onClick={() => handleThemeChange(changeToMQU, wordsContent.mquSocName)}>{wordsContent.mquSocName}</button></div>
              )} */}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>

      <div className={styles.menuButton}>
        <FontAwesomeIcon
          onClick={handleLanguageChange}
          className={`${styles.translationButtonPhone} ${themeStyles.translationButtonPhone}`}
          icon={fas.faEarthAmericas}
        />
        <Hamburger size={25} toggled={menuOpen} toggle={setMenuOpen} />
      </div>

      <div className={styles.buttonsBox}>
        <div className={styles.translationButton}>
          <FontAwesomeIcon
            onClick={handleLanguageChange}
            className={`${styles.translationButtonLaptop} ${themeStyles.translationButtonLaptop}`}
            icon={fas.faEarthAmericas}
          />
        </div>

        <div
          className={`${styles.tabs} ${menuOpen ? styles.open : styles.close}`}
        >
          <input
            id="About"
            type="radio"
            name="tabMenu"
            className={styles.input}
            checked={activeSection === "AboutUs"}
            readOnly
            onClick={() => scrollToSection("AboutUs")}
          />
          <input
            id="Events"
            type="radio"
            name="tabMenu"
            className={styles.input}
            checked={activeSection === "ComingEvents"}
            readOnly
            onClick={() => scrollToSection("ComingEvents")}
          />
          <input
            id="Blogs"
            type="radio"
            name="tabMenu"
            className={styles.input}
            checked={activeSection === "BlogSection"}
            readOnly
            onClick={() => scrollToSection("BlogSection")}
          />
          <input
            id="JoinUs"
            type="radio"
            name="tabMenu"
            className={styles.input}
            checked={activeSection === "BecomeMember"}
            readOnly
            onClick={() => scrollToSection("BecomeMember")}
          />
          <div className={`${styles.buttons} ${themeStyles.buttons}`}>
            <label
              htmlFor="About"
              className={`${styles.label} ${themeStyles.label}`}
            >
              {wordsContent.aboutNav}
            </label>
            <label
              htmlFor="Events"
              className={`${styles.label} ${themeStyles.label}`}
            >
              {wordsContent.eventNav}
            </label>
            <label
              htmlFor="Blogs"
              className={`${styles.label} ${themeStyles.label}`}
            >
              {wordsContent.blogNav}
            </label>
            <label
              htmlFor="JoinUs"
              className={`${styles.label} ${themeStyles.label}`}
            >
              {wordsContent.memberNav}
            </label>
            {language === "Chinese" && window.innerWidth > 760 ? (
              <div
                className={`${styles.underlineChinese} ${themeStyles.underlineChinese}`}
              ></div>
            ) : (
              <div
                className={`${styles.underline} ${themeStyles.underline}`}
              ></div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Navbar;
