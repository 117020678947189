import React from "react";
import { motion } from "framer-motion";
import proChannelQR from "./pro_channel_qr.jpg";
import unswChannelQR from "./unsw_channel_qr.jpg";
import umelbChannelQR from "./umelb_channel_qr.jpg";
import { Link } from "react-router-dom";

import styles from "./wechat.module.css";

function Wechat() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h1>Wechat Official Channel</h1>
      <p>Scan the QR code using wechat</p>
      <p>用微信扫描二维码，关注我们的公众号</p>
      <div className={styles.sectionContainer}>
        <div className={styles.orgContainer}>
          <h1>PRO 微信公众号</h1>
          <img className={styles.qrCode} src={proChannelQR} alt="error"></img>
        </div>
        <div className={styles.orgContainer}>
          <h1>UNSW 微信公众号</h1>
          <img className={styles.qrCode} src={unswChannelQR} alt="error"></img>
        </div>

        <div className={styles.orgContainer}>
          <h1>UMelb 微信公众号</h1>
          <img className={styles.qrCode} src={umelbChannelQR} alt="error"></img>
        </div>
      </div>
      <Link to="/" replace>
        <button>Go back to Home</button>
      </Link>
    </motion.div>
  );
}

export default Wechat;
