// Contact.js
import React from "react";
import { useNavigate } from "react-router-dom";

import styles from "./styles/Footer.module.css";
import { useTheme, useLanguage } from "./ThemeContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

import wordsJson from "./words.json";

function Contact() {
  const { language } = useLanguage();
  const headingContent = wordsJson.admin[language].footerSection;

  const { theme } = useTheme();
  const wordsData = wordsJson[theme][language].contact;

  const waveColors = ["#696969", "#4f5050", "#323333", "#171818"];

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/wechat");
  };

  return (
    <footer className={styles.box}>
      <svg
        className={styles.waves}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        shapeRendering="auto"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g className={styles.parallax}>
          <use xlinkHref="#gentle-wave" x="48" y="0" fill={waveColors[0]} />
          <use xlinkHref="#gentle-wave" x="48" y="3" fill={waveColors[1]} />
          <use xlinkHref="#gentle-wave" x="48" y="5" fill={waveColors[2]} />
          <use xlinkHref="#gentle-wave" x="48" y="7" fill={waveColors[3]} />
        </g>
      </svg>

      <div className={styles.content}>
        <div className={styles.orgInfoBox}>
          <p>{headingContent.societyName}</p>
          <p>ABN: 14 607 020 438</p>
          <p>
            © 2024, {headingContent.contentBeforeHeart}{" "}
            <i className={styles.heart}>
              <FontAwesomeIcon icon={fas.faHeart} beatFade />{" "}
            </i>{" "}
            {headingContent.contentAfterHeart}{" "}
          </p>
        </div>

        <div className={styles.contactBox}>
          <p className={styles.contactTitle}> {headingContent.title} </p>
          <a className={styles.icon} onClick={handleNavigate}>
            <FontAwesomeIcon icon={fab.faWeixin} />
          </a>
          <a
            className={styles.icon}
            href={wordsData.linkedinUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={fab.faLinkedin} />
          </a>
          <a
            className={styles.icon}
            href={wordsData.email}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={fas.faEnvelope} />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Contact;
