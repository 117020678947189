import React, { useEffect, useState } from "react";
import "./styles/Cover.css";
import coverLaptopPro from "./img/cover/coverProLaptop.jpg";
import coverLaptopUNSW from "./img/cover/coverUNSWLaptop.jpg";
import coverLaptopUMelb from "./img/cover/coverUMelbLaptop.jpg";
import coverLaptopMQU from "./img/cover/coverMQULaptop.jpg";
import coverMobilePro from "./img/cover/coverProMobile.jpg";
import coverMobileUNSW from "./img/cover/coverUNSWMobile.jpg";
import coverMobileUMelb from "./img/cover/coverUMelbMobile.jpg";
import coverMobileMQU from "./img/cover/coverMQUMobile.jpg";

import { useTheme, useLanguage } from "./ThemeContext";

import wordsJson from "./words.json";

function Cover() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const { theme } = useTheme();
  const { language } = useLanguage();

  const headingContent = wordsJson.admin[language].coverSection;

  function getCover(theme) {
    if (window.innerWidth > 768) {
      switch (theme) {
        case "Pro":
          return coverLaptopPro;
        case "UNSW":
          return coverLaptopUNSW;
        case "UMelb":
          return coverLaptopUMelb;
        case "MQU":
          return coverLaptopMQU;
        default:
          return coverLaptopPro;
      }
    } else {
      switch (theme) {
        case "Pro":
          return coverMobilePro;
        case "UNSW":
          return coverMobileUNSW;
        case "UMelb":
          return coverMobileUMelb;
        case "MQU":
          return coverMobileMQU;
        default:
          return coverMobilePro;
      }
    }
  }

  const cover = getCover(theme);

  useEffect(() => {
    let ticking = false;
    const handleScroll = () => {
      const position = window.pageYOffset;
      if (!ticking) {
        window.requestAnimationFrame(() => {
          setScrollPosition(position);
          ticking = false;
        });
        ticking = true;
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const backgroundStyle = {
    transform: `translateY(${-scrollPosition * 1.3}px)`,
    background: `url(${cover}) no-repeat center center / cover`,
  };

  const awsTextStyle = {
    transform: `translateY(${scrollPosition * 0.4}px)`,
    opacity: Math.max(1 - scrollPosition / 400, 0),
  };

  return (
    <div className="about-section">
      <div className="background-image" style={backgroundStyle} />
      <div id="Cover" className="aws-text" style={awsTextStyle}>
        <h1>{headingContent.title}</h1>
        <h2>{headingContent.subtitle}</h2>
      </div>
    </div>
  );
}

export default Cover;
